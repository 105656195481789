<template>
    <div class="projects">
        <div class="py-4 container-fluid">
        <h1 class="text-dark">Start a Project</h1>
        <br><br>
        <h3  class="text-dark">Samples</h3>
        <div class="row" >
          <!-- <div >'public/projects/'+this.project+'/'+this.project+'.zip' -->
            <div  style="max-width:200px; margin-bottom: 10px;" v-for="(element) in projects" :key="element[0]">
                <project-card
                  :project="`${element[0]}`"
                  :image="`${element[1]}`"
                  :title="`${element[0]}`"
                  :click="OnProject"
                  onDelete=""
                  onClone =""
                  description="Choose a character and 360 enviroment"
                  
                  
                />
            </div> 
        </div>
        <br><br>
        <h3 class="text-dark">My Projects</h3>
        
      <div class="row" >
        <pulse-loader :loading="loading" :color="color" :size="size" style="display:flex!important;" ></pulse-loader>
          <!-- <div >@click="OnProject(element[0],element[2])" -->
            <div  style="max-width:200px; margin-bottom: 10px;"  v-for="(element) in private_projects" :key="element[0]">
                <project-card
                  :project="`${element[0]}`"
                  :image="`${element[1]}`"
                  :title="`${element[2]}`"
                  :click="OnProject"
                  :onDelete ="OnDelete"
                  :onClone = "OnClone"
                  description="Choose a character and 360 enviroment"
                />
            </div> 
        </div>
      </div>
    </div>
  </template>
  <script>
//   import MiniStatisticsCard from "@/components/MiniStatisticsCard.vue";
  import ProjectCard from "@/components/ProjectCard.vue";
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

  import {
    faHandPointer,
    faUsers,
    faCreditCard,
    faScrewdriverWrench,
  } from "@fortawesome/free-solid-svg-icons";
  import { mapMutations } from "vuex";
  import { API } from 'aws-amplify';
  import * as aws_helper from "@/amplify_helper.js";
  import {v4 as uuid} from 'uuid';

  export default {
    name: "dashboard-default",
    async mounted(){
      this.$store.state.has3dEnvironment = false;
      this.$store.state.hasSlides = false;
      this.closeAllPanels();
      let local_json_url =await aws_helper.getS3File('public/projects/projects.json','no-store');
      this.projects = [];
      const jd = await fetch(local_json_url)
      const jdata = await jd.json();
      console.log(jdata);
      for(let i in jdata.Projects)
      {
        console.log(jdata.Projects[i]);
        this.projects.push([jdata.Projects[i],await aws_helper.getS3File('public/projects/'+jdata.Projects[i]+'/thumbnail.jpg','no-store')]);
      }
      this.lambda = this.callLambda();

    },
    data() {
      return {
        iconBackground: "bg-gradient-success",
        faCreditCard,
        faScrewdriverWrench,
        faUsers,
        faHandPointer,
        projects:[],
        private_projects:[],
        loading: false,
        color: '#d91b5c',
        size: '30px'

      };
    },
    methods:
    {
      ...mapMutations(["showNavbar", "closeAllPanels"]),
      OnProject(link, name)
      {
        
        this.$router.push({name:'viewer',params: { project: link, project_name :name }})
      },
      OnDummy(project_id)
      {
        project_id;
      },
      async OnDelete(project_id)
      {
        let self = this;
        this.$dialog.confirm('You are about to delete this project permanently!')
          .then(async function () {
            console.log('Clicked on delete '+project_id);
        
            const params = {   
                headers: { 
                    "Content-Type":"application/json"
                }, 
                body: { 
                    "project_id": project_id,
                    "last_id": 999999
                }
            }
            self.private_projects = [];
            self.loading = true;
            await API.del('getassets','/project', params);
            await self.callLambda();
          })
          .catch(function () {
            console.log('Clicked on cancel')
          });
       
        
      },
      async OnClone(project_id)
      {
        // this.$dialog.confirm('Please confirm to continue')
        //   .then(function () {
        //     console.log('Clicked on proceed')
        //   })
        //   .catch(function () {
        //     console.log('Clicked on cancel')
        //   });
        const params = {    
            headers: { 
                "Content-Type":"application/json"
            },
            body: {
                "project_id": project_id,
                "new_id": uuid()
            }
        }
        this.private_projects = [];
        this.loading = true;
        await API.put('getassets','/project', params);
        await this.callLambda();
      },
      OnBasic()
      {
        console.log("Load Basic");
        this.$router.push({name:'viewer',params: { project: 'Basic' }})
      },
      OnStage()
      {
        console.log("Load Stage");
        this.$router.push({name:'viewer',params: { project: 'Stage' }})
      },
      OnWorkout()
      {
        console.log("Load Workout");
        this.$router.push({name:'viewer',params: { project: 'Workout' }})
      },
      OnNew()
      {
        console.log("Load New");
      },
      GetThumbnail(name)
      {
        (async ()=>
        {
          return await aws_helper.getS3File('public/projects/'+name+'/thumbnail.jpg')
        })();
         
      },
      async callLambda()
      {
        this.loading = true;
        try{
          //var user = await Auth.currentAuthenticatedUser();
          const data = await API.get('getassets','/items');
          console.log(data)
          this.loading = false;
          this.private_projects = []
          //this.private_projects = data.project;
          for(let i in data.projects)
          {
            console.log(data.projects[i][2]);
            this.private_projects.push([data.projects[i][2], await aws_helper.getS3File(data.projects[i][1],'no-store'), data.projects[i][3], data.projects[i][0]]);
          }
          return data;
        }catch(err)
        {
            console.log('error: ',err);
            this.loading = false;
        }
        this.loading = false;
      },
    },
    components: {
    //   MiniStatisticsCard,
      ProjectCard,
      PulseLoader
    },
  };
  </script>
<style>
.dg-btn--cancel {
  color: #fefefe;
  background-color: #d91b5c;
}
.dg-btn--ok {
  color: #d91b5c;
  background-color: #fefefe;
  border-color: #d91b5c;
}

</style>