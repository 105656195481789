<template>
  
  <div class="card project" style="background-color:rgba(255, 255, 255, 0.5)!important; cursor: pointer; " 
    @mouseenter="hover = true"
    @mouseleave="hover = false" >
    <i v-if="hover && onDelete!=''" style="position:absolute; z-index: 9; cursor:pointer; bottom:10px; right:20px;" @click="onDelete(project)"><font-awesome-icon style="color:#d91b5c; box-shadow: 5px;" icon="fa-solid fa-trash" /></i>
    <i v-if="hover && onClone!=''" style="position:absolute; z-index: 9; cursor:pointer; bottom:10px; left:20px;" @click="onClone(project)"><font-awesome-icon style="color:#d91b5c; box-shadow: 5px;" icon="fa-solid fa-clone" /></i>
    <div class="p-2 mx-2 text-center d-flex justify-content-center " @click="click(project,title)">
      <div class="mt-2 page-header min-height-50 border-radius-xl ">
        <img :src="`${image}`"  width="150" height="150"  style="margin-top:0px;!important" >
      </div>
      <span class="mask opacity-10 "><h5 class="text-center bg-white shadow border-radius-xl" style="color:rgb(65, 77, 88); ">{{ title }}</h5> </span> 
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectCard",
  
  props: {
    click: {
      required: true,
    },
    onDelete: {
      required: true,
    },
    onClone: {
      required: true,
    },
    image: {
      type: String,
      required: true,
      default:"@/assets/img/basic.jpg",
    },
    project: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>
